import { StepIconProps } from '@mui/material';
import Check from '@mui/icons-material/Check';
import { QontoStepIconRoot } from './styles';
import useStepper from '#/hooks/useStepper';
import { IServiceStats } from '#/types/globalTypes';

interface QontoStepIconProps extends StepIconProps {
  service?: keyof IServiceStats;
}

function QontoStepIcon(props: QontoStepIconProps) {
  const { active, completed, className, service } = props;

  // @ts-ignore
  const { handleBack } = useStepper(false, service);

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" onClick={handleBack} />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

export default QontoStepIcon;
