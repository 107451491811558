import { useState } from 'react';
import useServiceStats from '#/hooks/useServiceStats';

const useStepper = (
  isLivingWill: boolean,
  service:
    | 'living_will'
    | 'power_of_attorney'
    | 'last_will'
    | 'downloaded'
    | 'marriage_contract'
    | 'important_information'
    | 'cohabitation_agreement'
    | undefined
    | any,
  stepsData?: any
) => {
  const stats = useServiceStats();

  const [activeStep, setActiveStep] = useState<number>(
    // @ts-ignore
    service === 'downloaded' ? 0 : stats[service]?.service?.step || 0
  );

  const handleNext = () => {
    if (activeStep === stepsData.length - 1) return;
    setActiveStep(
      (prevActiveStep) =>
        prevActiveStep + (prevActiveStep === 2 && isLivingWill ? 1 : 1)
    );
  };

  const handleBack = () => {
    if (activeStep === 0) return;
    setActiveStep(
      (prevActiveStep) => prevActiveStep - (prevActiveStep === 4 ? 2 : 1)
    );
  };

  return {
    activeStep,
    setActiveStep,
    handleNext,
    handleBack,
  };
};

export default useStepper;
